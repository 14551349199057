// @flow
const React = require('react');

function Selectmenu(props: {
    className: string,
    disabled: boolean,
    inline: boolean,
    label?: string,
    mini?: boolean,
    name: string,
    onChange: (SyntheticEvent<HTMLInputElement>) => void,
    options: {
        desc: string,
        val: string | number
    }[],
    placeholder: string,
    style: Object,
    // the value of the option that is selected
    value: string
}) {
    var options = props.options.filter(option => option.val && option.desc).map(option => (
        <option key={option.val} value={option.val}>
            {option.desc}
        </option>
    ));

    if (props.placeholder.length > 0) {
        options.unshift(
            <option key="__placeholder" value="">
                {props.placeholder}
            </option>
        );
    }

    return (
        <div key={props.name}>
            {props.label ? <label htmlFor={props.name}>{props.label}</label> : null}
            <select
                className={props.className}
                id={props.name}
                name={props.name}
                onChange={props.onChange}
                style={props.style}
                value={props.value}
            >
                {options}
            </select>
        </div>
    );
}

Selectmenu.defaultProps = {
    className: '',
    disabled: false,
    inline: false,
    mini: false,
    placeholder: '',
    showClearButton: false,
    style: {}
};

module.exports = Selectmenu;

// @flow
'use strict';
const React = require('react');
const classnames = require('classnames');

function Button(props: {
    children: any,
    className: string,
    disabled: boolean,
    extraLarge: boolean,
    formaction?: string,
    large: boolean,
    onClick: (SyntheticEvent<HTMLButtonElement>) => void,
    primary: boolean,
    style: ?Object,
    type: string
}) {
    const classes = classnames({
        'button-large': props.large,
        'button-xlarge': props.extraLarge,
        'pure-button': true,
        'pure-button-primary': props.primary,
        'pure-button-disabled': props.disabled,
        [props.className]: props.className.length > 0
    });

    return (
        <button
            className={classes}
            disabled={props.disabled}
            formAction={props.formaction}
            onClick={props.onClick}
            style={props.style}
            type={props.type}
        >
            {props.children}
        </button>
    );
}

Button.defaultProps = {
    className: '',
    disabled: false,
    extraLarge: false,
    large: false,
    onClick: () => {},
    primary: false,
    style: {},
    type: 'button'
};

module.exports = Button;

// @flow
let cookie;
let _namespace = 'default';

if (typeof window !== 'undefined') {
    cookie = require('component-cookie');
} else {
    cookie = function(key: string, value?: string | null, options?: any): ?string {
        return;
    };
}

module.exports = function(namespace: string) {
    _namespace = namespace || _namespace;

    return {
        clear: function(name: string) {
            let values = cookie(_namespace) || '{}';
            let parsedValues = JSON.parse(values);

            delete parsedValues[name];

            cookie(_namespace, JSON.stringify(parsedValues));
        },
        clearAll: function() {
            cookie(_namespace, null);
        },
        get: function(name: string) {
            let values = cookie(_namespace) || '{}';
            let parsedValues = JSON.parse(values);

            return parsedValues[name];
        },
        getAll: function() {
            let values = cookie(_namespace) || '{}';
            let parsedValues = JSON.parse(values);

            return parsedValues;
        },
        set: function(name: string, value: any) {
            let values = cookie(_namespace) || '{}';
            let parsedValues = JSON.parse(values);

            parsedValues[name] = value;

            cookie(_namespace, JSON.stringify(parsedValues));
        }
    };
};

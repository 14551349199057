// @flow
'use strict';
const React = require('react');
const _ = require('lodash');
const c = require('../../modules/consts');
const Row = require('../atoms/TableRow');
const Table = require('../atoms/Table');

function CasesTable(props: {
    cases: CaseRow[],
    renderCell: (any, any) => React$Element<*>,
    renderHeaderCell: any => React$Element<*>
}) {
    const rows = props.cases.map(row => {
        const cells = c.CASE_COLUMNS.map(cell => props.renderCell(row, cell));

        return <Row key={row.id}>{cells}</Row>;
    });
    const row = props.cases.length ? props.cases[0] : {};
    const header = (
        <thead>
            <tr>{c.CASE_COLUMNS.map(props.renderHeaderCell)}</tr>
        </thead>
    );

    return (
        <div>
            <Table
                className="pure-table pure-table-horizontal u-full-width is-responsive"
                header={header}
            >
                {rows}
            </Table>
        </div>
    );
}

CasesTable.defaultProps = {
    cases: [],
    renderCell: (row, cell) => <td key={cell}>{row[cell]}</td>,
    renderHeaderCell: cell => <th key={cell}>{cell}</th>
};

module.exports = CasesTable;

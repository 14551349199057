'use strict';

module.exports = {
    BASE_URL: process.env.BASE_URL || '/',
    BILLING_COLUMNS: [
        'creditRequest',
        'description',
        'hours',
        'price',
        'subtotal',
        'reason',
        'orderNumber'
    ],
    CASE_COLUMNS: [
        // listed in the order in which we want them to appear on screen
        'id',
        'title',
        'type',
        'status',
        'opened',
        'project',
        'bucket'
        // 'clientPriority'
    ],
    CLIENT_PRIORITIES: [
        { name: 'Emergency', value: 0 },
        { name: 'High', value: 1 },
        { name: 'Low', value: 2 },
        { name: 'Nice to have', value: 3 }
    ],
    CLIENT_PRIORITY_DEFAULT: 2,
    CLIENT_TYPES: { KEY: 'Key Account' },
    CONTACT_FIELD_LENGTHS: {
        password: 50,
        phone: 15,
        phoneExtension: 10,
        position: 35
    },
    COOKIE_NAME: 'client-tool',
    DATABASE_NAME: process.env.DATABASE || 'SBI_Development',
    DEFAULT_ASSIGN_TO: 'ST',
    DEFAULT_CASE_STATUS: 'Review',
    EMAIL_FROM: 'support@sbiteam.com',
    FILE_FIELD: 'myfile',
    PAGE_LIMIT: 50,
    PRIORITY_OPTIONS: [
        { name: 'pick a due date', value: 10 },
        { name: '24-48 hour turnaround', value: 1 },
        { name: 'emergency', value: 0 }
    ],
    PRIORITY_DEFAULT: 1,
    PROJECT_STATUSES: {
        0: 'closed',
        1: 'in progress',
        5: 'on hold',
        6: 'cancelled',
        7: 'scheduled'
    },
    PROJECT_TRUNCATE_LENGTH: 300,
    ROOT_ID: 'root',
    SUBTYPE_OPTIONS: ['bug', 'support', 'change request'],
    TITLE: 'SBI Client Support',
    USER_COLUMNS: [
        'email',
        'password',
        'phone',
        'phoneExtension',
        'position',
        'allowClientTool',
        'clientToolAdmin'
    ],
    UPLOAD_ACCEPT: {
        'application/msword': '/static/images/icon_doc.png',
        'application/pdf': '/static/images/icon_pdf.png',
        'application/vnd.ms-excel': '/static/images/icon_csv.png',
        'application/vnd.ms-outlook': '',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            '/static/images/icon_xls.png',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            '/static/images/icon_doc.png',
        'application/x-msexcel': '/static/images/icon_xls.png',
        'application/x-zip-compressed': '/static/images/icon_zip.png',
        'application/zip': '/static/images/icon_zip.png',
        'image/*': '',
        'text/csv': '/static/images/icon_csv.png',
        'text/plain': '/static/images/icon_txt.png',
        'video/*': ''
    },
    UPLOAD_DIR: process.env.UPLOAD_DIR || './uploads'
};

// @flow
'use strict';
const Dropzone = require('react-dropzone');
const React = require('react');
const Button = require('../atoms/Button');
const c = require('../../modules/consts');
const fetch = require('../../modules/fetch');
const ImagePreview = require('../atoms/ImagePreview');
const urlJoin = require('../../modules/url-join');

class UploadForm extends React.PureComponent<
    {
        accept: string,
        action: string,
        className: string,
        hiddenInputs: { name: string, value: number | string }[],
        label: any,
        submitText: string,
        clientId: string
    },
    { files: any[], filesWereDropped: boolean }
> {
    static defaultProps = {
        accept: c.UPLOAD_ACCEPT,
        action: `/upload`,
        className: '',
        hiddenInputs: [],
        label: 'Choose file to upload...',
        submitText: 'Upload',
        clientId: ''
    };
    constructor() {
        super();
        this.state = { files: [], filesWereDropped: false };
    }
    onDrop = (acceptedFiles: File[], rejectedFiles: File[], e: SyntheticDragEvent<*>) => {
        this.setState({
            files: acceptedFiles,
            // $FlowFixMe
            filesWereDropped: !e.currentTarget.files || e.currentTarget.files.length === 0
        });
    };
    onSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
        let formData = new FormData(e.currentTarget);
        if (this.state.filesWereDropped) {
            /* if the file input has no files, check state for files and add those to the form data.
             * This is necessary because dragging and dropping does not set the files attribute of
             * the file input, and it is not possible to set it from javascript for security reasons.
             */
            this.state.files.forEach(file => {
                formData.append(c.FILE_FIELD, file, file.name);
            });
        }

        fetch.post(this.props.action, formData, 'form-data').then(res => {
            window.location = res.url;
        });

        e.preventDefault();
    };
    render() {
        const props = this.props;
        const hiddenInputs = props.hiddenInputs.map(el => (
            <input key={el.name} name={el.name} type="hidden" value={el.value} />
        ));

        return (
            <form
                action={props.action}
                className={props.className}
                encType="multipart/form-data"
                method="post"
                onSubmit={this.onSubmit}
            >
                {hiddenInputs}
                <Dropzone
                    accept={props.accept}
                    activeClassName="is-active"
                    className="u-full-width c-upload"
                    multiple
                    name={c.FILE_FIELD}
                    onDrop={this.onDrop}
                    rejectClassName="is-rejected"
                >
                    <span className="u-mobile-only">{`Tap to attach files...`}</span>
                    <span className="u-desktop-only">
                        {'Click '}
                        <span className="u-js-only">{'or drag and drop '}</span>
                        {'to attach files...'}
                    </span>
                    <div style={{ display: 'block' }}>
                        {this.state.files.map(file => (
                            <ImagePreview key={file.name} file={file} />
                        ))}
                    </div>
                    <span>
                        {`Accepted file types: PDF, Excel, Word, images, ZIP files, and video.`}
                    </span>
                </Dropzone>
                <Button style={{ marginTop: '1em' }} type="submit">
                    {props.submitText}
                </Button>
            </form>
        );
    }
}

module.exports = UploadForm;

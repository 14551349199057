// @flow
'use strict';
const React = require('react');
const classnames = require('classnames');

function LinkButton(props: {
    children: any,
    className: string,
    disabled: boolean,
    href: string,
    primary: boolean
}) {
    const classes = classnames({
        'pure-button': true,
        'pure-button-primary': props.primary,
        'pure-button-disabled': props.disabled,
        [props.className]: props.className.length > 0
    });
    return (
        <a className={classes} href={props.href}>
            {props.children}
        </a>
    );
}

LinkButton.defaultProps = { className: '', disabled: false, primary: false };

module.exports = LinkButton;

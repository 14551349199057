// @flow
'use strict';
const classnames = require('classnames');
const moment = require('moment');
const React = require('react');

function NoteHeader(props: {
    className: string,
    date: Date,
    employeeId: string,
    style: Object,
    type: string
}) {
    const classes = classnames({
        'pure-g': true,
        [props.className]: props.className
    });
    /* because the db stores dates in PDT (but without timzeone info) and
     * Sequelize assumes that those dates are in UTC, we need to offset it in
     * the opposite direction for it to display correctly.
     */
    const userTime = moment(props.date);
    return (
        <div className={classes} style={props.style}>
            <div className="pure-u-1-2">{`${props.type} (${props.employeeId})`}</div>
            <div className="pure-u-1-2 u-text-right">{userTime.fromNow()}</div>
        </div>
    );
}

NoteHeader.defaultProps = { style: {} };

module.exports = NoteHeader;

// @flow
'use strict';
const React = require('react');

function Info(props: { children: any, label: string, style: ?Object }) {
    if (props.children) {
        return (
            <p style={props.style}>
                <strong>{props.label}</strong>
                <br />
                {props.children}
            </p>
        );
    }
    return null;
}

Info.defaultProps = { style: {} };

module.exports = Info;

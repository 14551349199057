// @flow
'use strict';
const React = require('react');

function Table(props: { children: any, className: string, header: any, style: Object }) {
    const noresults = (
        <tr>
            <td>
                <p style={{ textAlign: 'center' }}>{'No results to show.'}</p>
            </td>
        </tr>
    );
    const rows = props.children;

    return (
        <table className={props.className} style={props.style}>
            {props.header ? props.header : null}
            <tbody>{rows ? rows : noresults}</tbody>
        </table>
    );
}

Table.defaultProps = { className: '', style: {} };

module.exports = Table;

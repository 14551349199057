// @flow
const _ = require('lodash');
const { stringify } = require('query-string');
const React = require('react');
const Button = require('../atoms/Button');
const c = require('../../modules/consts');

function PaginateControls(props: {
    query: { offset?: string, limit?: string, search?: string },
    showBackToTop: boolean,
    showNext: boolean,
    showPrev: boolean
}) {
    const currentOffset = Number(props.query.offset) || 0;
    const prevQuery = _.assign({}, props.query, { offset: currentOffset - c.PAGE_LIMIT });
    const nextQuery = _.assign({}, props.query, { offset: currentOffset + c.PAGE_LIMIT });
    const disablePrevQuery = prevQuery.offset < 0;

    return (
        <div className="pure-g" style={{ marginTop: '1em' }} key={props.query.offset}>
            <form className="pure-u-1-3">
                {_.keys(prevQuery).map(key => (
                    <input key={key} name={key} type="hidden" value={prevQuery[key]} />
                ))}
                <Button disabled={disablePrevQuery} type="submit">
                    Previous
                </Button>
            </form>
            <div className="pure-u-1-3 u-text-center">
                {props.showBackToTop && (
                    <a href={`home/?${stringify(props.query)}#top`}>Back To Top</a>
                )}
            </div>
            <form className="pure-u-1-3 u-text-right">
                {_.keys(nextQuery).map(key => (
                    <input key={key} name={key} type="hidden" value={nextQuery[key]} />
                ))}
                <Button disabled={!props.showNext} type="submit">
                    Next
                </Button>
            </form>
        </div>
    );
}

PaginateControls.defaultProps = {
    query: { limit: c.PAGE_LIMIT.toString(), offset: '0' },
    showBackToTop: false,
    showNext: true,
    showPrev: true
};

module.exports = PaginateControls;

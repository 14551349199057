// @flow
const React = require('react');

function Input(props: { autoFocus: boolean, className: string, label?: string, name: string }) {
    return (
        <div>
            {props.label ? <label htmlFor={props.name}>{props.label}</label> : null}
            <input {...props} className={props.className || ''} id={props.name} />
        </div>
    );
}

Input.defaultProps = { autoFocus: false, className: '' };

module.exports = Input;

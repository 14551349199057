// @flow
'use strict';
const React = require('react');

module.exports = function TableRow(props: {
    children: React.Node,
    className?: string,
    data?: string | number | Object,
    onClick?: (SyntheticEvent<HTMLElement>, any) => void
}) {
    return (
        <tr
            className={props.className || ''}
            onClick={e => (props.onClick ? props.onClick(e, props.data) : undefined)}
        >
            {props.children}
        </tr>
    );
};

// @flow
const React = require('react');
const c = require('../../modules/consts');
const Tooltip = require('./Tooltip');
const urlJoin = require('../../modules/url-join');

function MenuLink(props: { children: any, clientId: string, selected: boolean, style: any }) {
    console.log('Props: ', props);
    return (
        <li className={`pure-menu-item ${props.selected ? 'pure-menu-selected' : ''}`}>
            <a
                href={`${
                    process.env.BILLPAY_URL
                }/account-overview?clientId=41DA56D4-B883-4A4A-8A3F-AA2C2ED133F6&companyId=${
                    props.clientId
                }`}
                className="pure-menu-link"
                style={props.style}
                target="_blank"
            >
                {props.children}
            </a>
        </li>
    );
}

MenuLink.defaultProps = { selected: false };

module.exports = MenuLink;

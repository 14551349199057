// @flow
'use strict';
const React = require('react');
const classnames = require('classnames');
const c = require('../../modules/consts');
const urlJoin = require('../../modules/url-join');
const MenuItem = require('../atoms/MenuItem');
const MenuLink = require('../atoms/MenuLink');

class HeaderResponsive extends React.PureComponent<
    {
        companyName: string,
        clientId: string
    },
    {
        admin?: boolean,
        loggedIn?: boolean,
        spoof?: Spoof
    },
    { open: boolean }
> {
    static defaultProps = { admin: false, loggedIn: false };
    constructor() {
        super();
        this.state = { open: false, showPopup: false };
    }
    onToggle = () => {
        setTimeout(() => this.setState({ open: !this.state.open }), 500);
    };

    togglePopup = () => {
        this.setState({ showPopup: !this.state.showPopup });
    };
    render() {
        const props = this.props;
        const burgerButton = (
            <a
                onClick={this.onToggle}
                className={`custom-toggle ${this.state.open ? 'x' : ''}`}
                href="#"
            />
        );
        const headerclasses = classnames({
            'c-header': true,
            'u-screen-only': true,
            'pure-g': true,
            'is-open': this.state.open
        });

        return (
            <div>
                <header className={headerclasses}>
                    <div className="pure-u-1 pure-u-md-1-3">
                        <div className="pure-menu">
                            <a
                                className="pure-menu-heading pure-menu-link custom-brand"
                                href={`${c.BASE_URL}home?${
                                    props.clientId ? `clientId=${props.clientId}` : ''
                                }`}
                            >
                                {c.TITLE} {props.companyName ? ` / ${props.companyName}` : ``}
                            </a>
                            {props.loggedIn && burgerButton}
                        </div>
                    </div>
                    {props.loggedIn ? (
                        <div className="pure-u-1 pure-u-md-2-3">
                            <div
                                className={`pure-menu custom-menu-3 custom-can-transform u-mobile-only`}
                            >
                                <ul className="pure-menu-list">
                                    {/* <MenuItem url={`/`}>{`Home`}</MenuItem> */}
                                    {/* <MenuItem url={`/projects?live=true`}>{`What's New`}</MenuItem> */}
                                    <MenuItem
                                        clientId={`/cases/new?&clientId=${props.clientId}`}
                                    >{`New Case`}</MenuItem>
                                    <a
                                        className="platform-redirect"
                                        href={`${process.env.PLATFORM_URL}`}
                                    >{`Platform`}</a>
                                </ul>
                            </div>
                            <div
                                className={`pure-menu pure-menu-horizontal custom-menu-3 custom-can-transform u-desktop-only`}
                            >
                                <ul className="pure-menu-list">
                                    {/* <MenuItem url={`/`}>{`Home`}</MenuItem> */}
                                    {/* <MenuItem url={`/projects?live=true`}>{`What's New`}</MenuItem> */}
                                    <MenuItem
                                        url={`/cases/new?clientId=${props.clientId}`}
                                    >{`New Case`}</MenuItem>
                                    <MenuItem
                                        url={`/docs?clientId=${props.clientId}`}
                                    >{`General Help`}</MenuItem>
                                    <MenuItem
                                        url={`/releasenotes?clientId=${props.clientId}`}
                                    >{`Release Notes`}</MenuItem>
                                    <MenuItem
                                        url={`/your-resources?clientId=${props.clientId}`}
                                    >{`Your Resources`}</MenuItem>
                                    {props.admin && (
                                        <MenuLink clientId={props.clientId}>{`Bill Pay`}</MenuLink>
                                    )}
                                    {props.admin && (
                                        <div onClick={this.togglePopup}>
                                            <MenuItem
                                                style={{ color: 'yellow' }}
                                                url="#"
                                                //url={`/admin/billing/pay?clientId=${props.clientId}`}
                                            >{`Pay Bills and View Balances`}</MenuItem>
                                        </div>
                                    )}
                                    {this.state.showPopup && (
                                        <div className="popupContainer">
                                            <div className="popupContent">
                                                {/* Popup content goes here */}
                                                <p>
                                                    This feature is currently disabled, and will be
                                                    replaced with our new Bill Pay application soon.
                                                </p>
                                                <br />
                                                <p>
                                                    For the time being, please contact us at
                                                    billing@sbiteam.com to make payment.
                                                </p>
                                                <button onClick={this.togglePopup}>Close</button>
                                            </div>
                                        </div>
                                    )}
                                    <a
                                        className="platform-redirect"
                                        href={`${process.env.PLATFORM_URL}`}
                                    >{`Platform`}</a>
                                </ul>
                            </div>
                        </div>
                    ) : null}
                </header>
                {props.spoof ? (
                    <div className="c-warning">
                        {`Spoofing user ${props.spoof.username} / ${props.spoof.companyName} `}
                        <a href="/superuser/logout">{'(Log out)'}</a>
                    </div>
                ) : null}
            </div>
        );
    }
}

module.exports = HeaderResponsive;

// @flow
const React = require('react');
const c = require('../../modules/consts');
const urlJoin = require('../../modules/url-join');

function ImagePreview(props: { file: { name: string, preview: ?any, type: string } }) {
    let src = c.UPLOAD_ACCEPT[props.file.type];

    if (!src || src.length === 0) {
        src = props.file.preview;
    } else {
        src = urlJoin([c.BASE_URL, src]);
    }

    return (
        <div className="c-preview-img">
            <img className="pure-img" src={src} />
            <span>{props.file.name}</span>
        </div>
    );
}

module.exports = ImagePreview;

// @flow
const React = require('react');
const c = require('../../modules/consts');
const Tooltip = require('./Tooltip');
const urlJoin = require('../../modules/url-join');
const MenuItem = require('./MenuItem');

function AdminMenu(props: { clientId: string, url: string }) {
    return (
        <div className="pure-menu pure-menu-horizontal" style={{ marginBottom: '1em' }}>
            <ul className="pure-menu-list">
                {/* <MenuItem selected={props.url === `/admin`} url={`/admin`}>
                    {'Users'}
                </MenuItem> */}
                <MenuItem
                    selected={props.url === `/admin/billing`}
                    url={`/admin/billing?clientId=${props.clientId}`}
                >
                    {'Billing'}
                </MenuItem>
            </ul>
        </div>
    );
}

AdminMenu.defaultProps = { url: '' };

module.exports = AdminMenu;

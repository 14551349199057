// @flow
const React = require('react');

class Textarea extends React.PureComponent<{
    className: string,
    label: string,
    name: string,
    rows: number
}> {
    _input: ?HTMLTextAreaElement;
    constructor() {
        super();
    }
    focus() {
        if (this._input) {
            this._input.focus();
        }
    }
    render() {
        const props = this.props;

        return (
            <div>
                {props.label ? <label htmlFor={props.name}>{props.label}</label> : null}
                <textarea
                    {...props}
                    className={props.className}
                    id={props.name}
                    ref={c => (this._input = c)}
                />
            </div>
        );
    }
}

module.exports = Textarea;

const React = require('react');
const urlJoin = require('./url-join');
const c = require('./consts');
const urlExpression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/g;

function addLinks(el, clientId) {
    if (!el) {
        return null;
    }

    return el.split(' ').map((word, i) => {
        let caseLinkMatch = word.match(/\b\d{6}\b/g);
        let urlMatch = word.match(urlExpression);

        // replaces all full URLs with anchors
        if (urlMatch && urlMatch.length) {
            return (
                <span key={i}>
                    <a href={urlMatch[0]}>{urlMatch[0]}</a>{' '}
                </span>
            );
        }

        // replaces all instances of six numbers delineated by word borders with links to cases
        if (caseLinkMatch && caseLinkMatch.length) {
            let rtn = word.split(caseLinkMatch[0]);
            return (
                <span key={i}>
                    {rtn[0]}
                    <a
                        href={urlJoin([
                            c.BASE_URL,
                            `/cases/${caseLinkMatch[0]}?clientId=${clientId}`
                        ])}
                    >
                        {caseLinkMatch[0]}
                    </a>
                    {rtn[1]}{' '}
                </span>
            );
        }

        return <span key={i}>{word} </span>;
    });
}

module.exports = addLinks;

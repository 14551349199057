const React = require('react');

class Checkbox extends React.PureComponent<
    {
        checked: boolean,
        defaultChecked: boolean,
        indeterminate: boolean,
        label: string | React$Element<*>,
        labelClassName: string,
        name: string,
        onChange: SyntheticEvent => void,
        readOnly: boolean,
        style: Object
    },
    {}
> {
    componentDidUpdate() {
        this.setIsIndeterminate();
    }
    componentDidMount() {
        this.setIsIndeterminate();
    }
    setIsIndeterminate() {
        this.checkbox.indeterminate = this.props.checked && this.props.indeterminate ? true : false;
    }
    render() {
        const props = this.props;
        return (
            <label className={props.labelClassName} htmlFor={props.name} style={props.style}>
                <input
                    checked={props.checked}
                    defaultChecked={props.defaultChecked}
                    id={props.name}
                    name={props.name}
                    onChange={props.onChange}
                    readOnly={props.readOnly}
                    ref={c => (this.checkbox = c)}
                    style={{ marginRight: props.label.length > 0 ? '.5em' : 'inherit' }}
                    type="checkbox"
                />
                {props.label}
            </label>
        );
    }
}

Checkbox.defaultProps = {
    indeterminate: false,
    label: '',
    labelClassName: '',
    readOnly: false,
    style: {}
};

module.exports = Checkbox;

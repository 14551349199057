// @flow
const React = require('react');

function Tooltip(props: {
    className: string,
    content: string,
    position: 'top' | 'right' | 'bottom' | 'left',
    tooltip: string
}) {
    return (
        <span
            className={`c-tooltip-${props.position} ${props.className}`}
            data-tooltip={props.tooltip}
        >
            {props.content}
        </span>
    );
}

Tooltip.defaultProps = { className: '', content: '(?)', position: 'right' };

module.exports = Tooltip;

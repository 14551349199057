const React = require('react');
const _ = require('lodash');
const addLinks = require('../../modules/add-links');

function Paragraphs(props: {
    className: string,
    newlinesOnly: boolean, // if true, we render spans with line breaks, instead of paragraphs
    style: Object,
    text: string,
    truncate: number
}) {
    const myProps = _.assign({}, props);
    let text = props.text;
    delete myProps.className;
    delete myProps.text;
    delete myProps.truncate;

    if (props.text) {
        if (props.truncate !== -1) {
            text = _.truncate(text, {
                length: props.truncate,
                omission: '',
                separator: '\n'
            });
        }
        const spans = text
            .split('\n')
            .filter(el => props.newlinesOnly || el.trim().length > 0)
            .map(addLinks)
            .map((el, i) => {
                if (props.newlinesOnly) {
                    return [
                        <span key={i} className={props.className} style={props.style}>
                            {el}
                        </span>,
                        <br />
                    ];
                }
                return (
                    <p key={i} className={props.className} style={props.style}>
                        {el}
                    </p>
                );
            });
        return <div className={props.className}>{spans}</div>;
    }
    return <span />;
}

Paragraphs.defaultProps = { newlinesOnly: false, truncate: -1 };

module.exports = Paragraphs;

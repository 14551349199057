// @flow
const React = require('react');
const _ = require('lodash');

function FilePreview(props: {
    className?: string,
    filename: string,
    filepath: string,
    style: Object
}) {
    const isImage =
        props.filepath.toLowerCase().indexOf('.jpg') !== -1 ||
        props.filepath.toLowerCase().indexOf('.gif') !== -1 ||
        props.filepath.toLowerCase().indexOf('.png') !== -1;
    const href = isImage ? `/image?filepath=${encodeURIComponent(props.filepath)}` : props.filepath;

    return (
        <a
            className={props.className}
            href={href}
            style={_.assign({ padding: '0 .5em .5em 0', display: 'block' }, props.style)}
            target="_blank"
        >
            {isImage ? (
                <img className="pure-img c-preview-image" src={props.filepath} />
            ) : (
                _.last(props.filename.split('/'))
            )}
        </a>
    );
}

FilePreview.defaultProps = { className: '', style: {} };

module.exports = FilePreview;

require('isomorphic-fetch');
const _ = require('lodash');
const { stringify } = require('query-string');
const createError = require('http-errors');
const c = require('./consts');
const urlJoin = require('./url-join');

module.exports.get = function get(resource, data, encoding) {
    return fetch(`${resource}?${stringify(data)}`, {
        method: 'GET',
        credentials: 'include'
    })
        .then(checkStatus)
        .then(parseJSON);
};

module.exports.post = function post(
    resource,
    data,
    encoding,
    options = { accept: 'application/json', parseResponse: true }
) {
    const contentType = getEncoding(encoding);
    let headers = {
        Accept: options.accept
    };
    if (contentType.length > 0) {
        headers['Content-Type'] = contentType;
    }
    return fetch(resource, {
        method: 'POST',
        headers: headers,
        // body: encodeBody(data, encoding),
        body: data,
        credentials: 'include'
    })
        .then(checkStatus)
        .then(response => (options.parseResponse ? parseJSON(response) : response));
};

function checkStatus(response) {
    if (!response.ok) {
        return parseJSON(response).then((body: { error: string }) => {
            var errorMessage =
                (body && body.error && body.error.message) ||
                response.headers.get('Reason-Phrase') ||
                response.statusText;
            var err = createError(response.status, errorMessage);
            throw err;
        });
    }
    return response;
}

function getEncoding(friendlyName: string): string {
    switch (friendlyName) {
        case 'json':
            return 'application/json';
        case 'form-data':
            return '';
        case 'urlencoded':
        case 'form':
        default:
            return 'application/x-www-form-urlencoded';
    }
}

function parseJSON(response): Promise<any> {
    if (_.includes(response.headers.get('Content-Type'), 'application/json')) {
        return response.json();
    }
    return Promise.resolve(response);
}

// @flow
const React = require('react');
const c = require('../../modules/consts');
const Tooltip = require('./Tooltip');
const urlJoin = require('../../modules/url-join');

function MenuItem(props: {
    children: any,
    clientId: string,
    selected: boolean,
    style: any,
    url: string
}) {
    return (
        <li className={`pure-menu-item ${props.selected ? 'pure-menu-selected' : ''}`}>
            <a
                href={urlJoin([c.BASE_URL, props.url])}
                className="pure-menu-link"
                style={props.style}
            >
                {props.children}
            </a>
        </li>
    );
}

MenuItem.defaultProps = { selected: false };

module.exports = MenuItem;
